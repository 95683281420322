import React, { useEffect, useState } from "react";
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import moment from 'moment'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Header from "./Header";
import { makeStorePathMaterials } from "./ProjectSummaryPage";
import './../index.css';
import countryListRaw from '../data/countries.json'
import cityListRaw from '../data/cities.json'
import { onlyUnique } from "../HelperFunctions";
import { config } from '../config'
import { StoreNavBar } from './StoreNavBar'
import { loadUsers } from './AdminUsersPage'
import { createStore, loadStore, scorecardTypes, Store, updateStore } from "./AdminStoresPage";
import { AccessControl } from "../AccessControl"

// Modify the country list to match Aesop naming
const countryList =
	countryListRaw.map(c => {
		if (c.code === "MO") {
			c.name = "Macau"
		} else if (c.code === "RU") {
			c.name = "Russia"
		} else if (c.code === "US") {
			c.name = "USA"
		} else if (c.code === "KR") {
			c.name = "South Korea"
		} else if (c.code === "TW") {
			c.name = "Taiwan"
		} else if (c.code === "VN") {
			c.name = "Vietnam"
		}

		return c
	})

// create key field we need for react array component
const cityList =
	cityListRaw
		.map(c => {
			const key = `${c.country}_${c.name.replace(/\ /g, '_')}`
			
			return {
				...c,
				key
			}
		})

// const region = [
// 	{ id: 1, name: "ANZ" },
// 	{ id: 2, name: "Asia" },
// 	{ id: 3, name: "Americas" },
// 	{ id: 4, name: "Europe" },
// ];

const regionList = [
	{
		id: 1,
		name: "ANZ",
		countries: [
			"Australia",
			"New Zealand",
		],
	},
	{
		id: 2,
		name: "Europe",
		countries: [
			"Austria",
			"Belgium",
			"Czech Republic",
			"Denmark",
			"France",
			"Finland",
			"Greece",
			"Germany",
			"Ireland",
			"Italy",
			"Netherlands",
			"Norway",
			"Poland",
			"Portugal",
			"Spain",
			"Sweden",
			"Switzerland",
			"Turkey",
			"United Kingdom",
		],
	},
	{
		id: 3,
		name: "EMEA",
		countries: [
			"Israel",
			"Kuwait",
			"Qatar",
			"Russia",
			"Saudi Arabia",
			"United Arab Emirates",
	
		],
	},
	{
		id: 4,
		name: "Americas",
		countries: [
			"Brazil",
			"Canada",
			"Chile",
			"Mexico",
			"USA",
		],
	},
	{
		id: 5,
		name: "Asia",
		countries: [
			"China",
			"Hong Kong",
			"Indonesia",
			"Japan",
			"Macau",
			"Malaysia",
			"Philippines",
			"Singapore",
			"South Korea",
			"Taiwan",
			"Thailand",
			"Vietnam",
		],
	}
]

const locationType = [
	{ id: 1, name: "Store" },
	{ id: 2, name: "Counter" },
];

const marketingMediaType = [
	{ id: 0, name: "None" },
	{ id: 1, name: "Digital Screen" },
	{ id: 2, name: "Lightbox" },
	{ id: 3, name: "Other" },
];

// const selectOption = [
// 	{ id: 1, option: "Yes" },
// 	{ id: 2, option: "No" },
// ];

const currencyList = [
	{ id: "AUD" },
	{ id: "EUR" },
	{ id: "GBP" },
	{ id: "USD" },
	{ id: "---" },
	{ id: "AED" },
	{ id: "AFN" },
	{ id: "ALL" },
	{ id: "AMD" },
	{ id: "ANG" },
	{ id: "AOA" },
	{ id: "ARS" },
	{ id: "AWG" },
	{ id: "AZN" },
	{ id: "BAM" },
	{ id: "BBD" },
	{ id: "BDT" },
	{ id: "BGN" },
	{ id: "BHD" },
	{ id: "BIF" },
	{ id: "BMD" },
	{ id: "BND" },
	{ id: "BOB" },
	{ id: "BOV" },
	{ id: "BRL" },
	{ id: "BSD" },
	{ id: "BTN" },
	{ id: "BWP" },
	{ id: "BYN" },
	{ id: "BZD" },
	{ id: "CAD" },
	{ id: "CDF" },
	{ id: "CHE" },
	{ id: "CHF" },
	{ id: "CHW" },
	{ id: "CLF" },
	{ id: "CLP" },
	{ id: "CNY" },
	{ id: "COP" },
	{ id: "COU" },
	{ id: "CRC" },
	{ id: "CUC" },
	{ id: "CUP" },
	{ id: "CVE" },
	{ id: "CZK" },
	{ id: "DJF" },
	{ id: "DKK" },
	{ id: "DOP" },
	{ id: "DZD" },
	{ id: "EGP" },
	{ id: "ERN" },
	{ id: "ETB" },
	{ id: "EUR" },
	{ id: "FJD" },
	{ id: "FKP" },
	{ id: "GBP" },
	{ id: "GEL" },
	{ id: "GHS" },
	{ id: "GIP" },
	{ id: "GMD" },
	{ id: "GNF" },
	{ id: "GTQ" },
	{ id: "GYD" },
	{ id: "HKD" },
	{ id: "HNL" },
	{ id: "HRK" },
	{ id: "HTG" },
	{ id: "HUF" },
	{ id: "IDR" },
	{ id: "ILS" },
	{ id: "INR" },
	{ id: "IQD" },
	{ id: "IRR" },
	{ id: "ISK" },
	{ id: "JMD" },
	{ id: "JOD" },
	{ id: "JPY" },
	{ id: "KES" },
	{ id: "KGS" },
	{ id: "KHR" },
	{ id: "KMF" },
	{ id: "KPW" },
	{ id: "KRW" },
	{ id: "KWD" },
	{ id: "KYD" },
	{ id: "KZT" },
	{ id: "LAK" },
	{ id: "LBP" },
	{ id: "LKR" },
	{ id: "LRD" },
	{ id: "LSL" },
	{ id: "LYD" },
	{ id: "MAD" },
	{ id: "MDL" },
	{ id: "MGA" },
	{ id: "MKD" },
	{ id: "MMK" },
	{ id: "MNT" },
	{ id: "MOP" },
	{ id: "MRU" },
	{ id: "MUR" },
	{ id: "MVR" },
	{ id: "MWK" },
	{ id: "MXN" },
	{ id: "MXV" },
	{ id: "MYR" },
	{ id: "MZN" },
	{ id: "NAD" },
	{ id: "NGN" },
	{ id: "NIO" },
	{ id: "NOK" },
	{ id: "NPR" },
	{ id: "NZD" },
	{ id: "OMR" },
	{ id: "PAB" },
	{ id: "PEN" },
	{ id: "PGK" },
	{ id: "PHP" },
	{ id: "PKR" },
	{ id: "PLN" },
	{ id: "PYG" },
	{ id: "QAR" },
	{ id: "RON" },
	{ id: "RSD" },
	{ id: "RUB" },
	{ id: "RWF" },
	{ id: "SAR" },
	{ id: "SBD" },
	{ id: "SCR" },
	{ id: "SDG" },
	{ id: "SEK" },
	{ id: "SGD" },
	{ id: "SHP" },
	{ id: "SLL" },
	{ id: "SOS" },
	{ id: "SRD" },
	{ id: "SSP" },
	{ id: "STN" },
	{ id: "SVC" },
	{ id: "SYP" },
	{ id: "SZL" },
	{ id: "THB" },
	{ id: "TJS" },
	{ id: "TMT" },
	{ id: "TND" },
	{ id: "TOP" },
	{ id: "TRY" },
	{ id: "TTD" },
	{ id: "TWD" },
	{ id: "TZS" },
	{ id: "UAH" },
	{ id: "UGX" },
	{ id: "USD" },
	{ id: "USN" },
	{ id: "UYI" },
	{ id: "UYU" },
	{ id: "UYW" },
	{ id: "UZS" },
	{ id: "VES" },
	{ id: "VND" },
	{ id: "VUV" },
	{ id: "WST" },
	{ id: "XAF" },
	{ id: "XCD" },
	{ id: "XDR" },
	{ id: "XOF" },
	{ id: "XPF" },
	{ id: "XSU" },
	{ id: "XUA" },
	{ id: "YER" },
	{ id: "ZAR" },
	{ id: "ZMW" },
	{ id: "ZWL" },
]

//variable kept for input field debugging
let storeDetailsStateDebug = !config.isDebugMode ? {} : {
	// "storeCode": null,
	"storeName": `Store ${Math.round(Math.random()*1000)}`,
	"scorecardType": Store.ScorecardType.CONCEPT,
	"locationType": "Store",
	"streetAddress": "1 Sample Street",
	"region": "ANZ",
	"country": "Australia",
	"city": "Melbourne",
	"roicCapex": 1,
	"currency": "AUD",
	"leaseTermInYears": 1,
	// "projectManagerId": null,
	"projectManager": "PM",
	// "architectId": null,
	"architect": "Arch",
	// "signedCoc": null,
	// "signedNda": null,
	"totalSqm": 1000,
	"retailSqm": 400,
	// "bohSqm": null,
	"marketingMediaType": "Digital Screen",
	"pos": 2,
	// "mpos": null,
	// "tapNumber": null,
	"treatmentSpace": 2,
	// "bathroom": null,
	// "otherSpace": null,
	// "reviewerId": null,
	// "reviewRequired": null,
	// "susInitGreenspace": null,
	// "susInitRainwater": null,
	// "susInitSolarPV": null,
	// "susInitGreywater": null,
	// "susInitBattery": null,
	// "susInitVentilation": null,
	// "susInitComposting": null,
	"contractorId": "Contractor",
	"storeStatus": "DRAFT",
	// "creatorId": null
};

// let storeDetailsStateDebug = {
// 	"storeName": "Otto 1",
// 	"creatorId": 12,
// 	"architectId": 12,
// 	"ready": "ready",
// 	"treatmentSpace": 3,
// 	"scorecardType": Store.ScorecardType.CONCEPT,
// 	"locationType": "Store",
// 	"streetAddress": "a",
// 	"region": "ANZ",
// 	"country": "Åland Islands",
// 	"city": "Eckerö",
// 	"currency": "GBP",
// 	"retailSqm": 222,
// 	"totalSqm": 1111,
// 	"bohSqm": 889
//   }

const getCityListBasedOnCountry = (country) => {
	if (country) {
		let cf =
			cityList
			.filter(c => c.country === country.code)
			// .sort((a, b) => a.name.localeCompare(b.name))
			.map(c => c.name)
			.filter(onlyUnique) // remove duplicate city names
			.sort()

		// console.log('filtered cities count', cf.length)
		// console.log('filtered cities', cf)
		return cf
	} else {
		// reset the cities list if we could not find the country code
		// return cityList
		return []
	}
}

const findCountryFromName = (countryName) => {
	return countryList.find(c => c.name === countryName)
}

const getCountryListBasedOnRegionName = (regionName) => {
	if (!regionName) {
		return []
	}
	
	let region = regionList.find(c => c.name === regionName)
	
	if (!region) {
		console.error("Could not find region:", regionName)
		return []
	} else {
		return region.countries
			.map(findCountryFromName)
			.filter(c => c !== undefined)
	}
}

const getCityListBasedOnCountryName = (countryName) => {
	let country = findCountryFromName(countryName)
	return getCityListBasedOnCountry(country)
}

const jsDateTimeToDateStr = (dt) => {
	const d = moment(dt)
	const f = d.format("yyyy-MM-DD")
	// console.log('moment', d)
	// console.log('moment formatted', f)
	return f
}

function ProjectCreateNew({ store, setStore, user: userProp }) {
	const storeId = store && store.id
	const editMode = !!storeId
	// console.log('ProjectCreateNew', userProp) // user prop is not set for the create store route (by the router)
	// const userId = parseInt(useSelector((state) => state.userId))
	const user = useSelector((state) => state.user)
	const userId = user.id
	// console.log('ProjectCreateNew', user)

	const storeDetailsStateDefault =
		{
			storeStatus: Store.Status.DRAFT,
			reviewRequired: 0,
			// ...storeDetailsStateDebug,
			// region: regionList[0].name,
			creatorId: userId,
			architectId: userId, // since we are no longer setting this field, set it to the userId so the user can access their own projects!
			openingDate: jsDateTimeToDateStr(new Date())
		}

	const [userList, setUserList] = useState([]);
	const [usersArchitect, setUsersArchitect] = useState([]);
	const [usersReviewer, setUsersReviewer] = useState([]);
	const [storeDetailsState, setStoreDetailsState] = useState(storeDetailsStateDefault)

	const [countryListFiltered, setCountryListFiltered] = useState(getCountryListBasedOnRegionName(storeDetailsState.region));
	const [cityListFiltered, setCityListFiltered] = useState(getCityListBasedOnCountryName(storeDetailsState.country));

	const dispatch = useDispatch();

	useEffect(() => {

		if (editMode === true) {
			// loadStore(storeId, (store) => {
			// console.log("store:", store)
			setCountryListBasedOnRegionName(store.region)
			setCityListBasedOnCountryName(store.country)
			setStoreDetailsState({ ...store  });
		// })
		} else {
			// new store
			setStoreDetailsState(storeDetailsStateDefault)
		}
		

		//look up users to prefill arch as dropdown - TODO: Remove (Not needed anymore since architect/pm are text fields)
		loadUsers(users => {
			// console.log(users)
			setUserList(users)
			const usersArchitectTmp = users.filter((user) => AccessControl.isDesigner(user))
			// console.log(usersArchitect);
			setUsersArchitect(usersArchitectTmp);
			let usersReviewerTmp = users.filter((user) => AccessControl.isReviewer(user))
			// console.log(usersReviewer);
			setUsersReviewer(usersReviewerTmp);
		})

	}, [editMode, storeId]);

	const [validated, setValidated] = useState(false);
	
	const setCountryListBasedOnRegionName = (regionName) => {
		const l = getCountryListBasedOnRegionName(regionName)
		setCountryListFiltered(l)
	}
	
	const setCityListBasedOnCountry = (country) => {
		// console.log("country", country)
		// console.log("country.code", country.code)
		const cl = getCityListBasedOnCountry(country)
		setCityListFiltered(cl)
	}

	const setCityListBasedOnCountryName = (countryName) => {
		let country = countryList.find(c => c.name === countryName)
		setCityListBasedOnCountry(country)
	}
	
	const calcBohSqm = (storeDetailsState) => {
		if (storeDetailsState
			&& storeDetailsState.totalSqm
			&& isFinite(storeDetailsState.totalSqm)
			&& storeDetailsState.retailSqm
			&& isFinite(storeDetailsState.retailSqm)) {
			const bohSqm = storeDetailsState.totalSqm - storeDetailsState.retailSqm

			if (storeDetailsState.bohSqm !== bohSqm) {
				setStoreDetailsState({ ...storeDetailsState, bohSqm }); // infinite loop!
			}

			return bohSqm
		} else {
			return ""
		}
	}

	const onChange = (e) => {
		// console.log("storeDetailsState", storeDetailsState);
		// console.log("cityListFiltered", cityListFiltered);
		//var inputName;
		var inputValue;
		var inputName = [e.target.name];
		inputValue = e.target.value;

		var storeDetailsStateUpdated = storeDetailsState

		if (e.target.name === "region") {
			// region was changed, so filter countries list

			// first reset the country and city selection
			storeDetailsStateUpdated.country = undefined
			storeDetailsStateUpdated.city = undefined
			setCityListFiltered([])

			let regionName = inputValue
			setCountryListBasedOnRegionName(regionName)
		}

		if (e.target.name === "country") {
			// country was changed, so filter cities list
			let country = countryList.find(c => c.name === inputValue)
			setCityListBasedOnCountry(country)

			// reset the city selection
			storeDetailsStateUpdated.city = undefined
		}

		if (e.target.type == "number") {
			// console.log("i am a number");
			//projectData[e.target.name] = Number(e.target.value);
			inputValue = parseInt(e.target.value);
		}

		if (e.target.type == "checkbox") {
			// console.log(e.target.type);
			// console.log(e.target.checked);
			//projectData[e.target.name] = e.target.checked;
			inputValue = e.target.checked;
		}

		if (e.target.type == "select") {
			// console.log(e.target.type);
			//e.target.value = projectData[e.target.name];
			e.target.id = e.target.id;
		}

		if (e.target.type == "date") {
			inputValue = e.target.value && new Date(e.target.value)
			// console.log('datestring', e.target.value)
			// console.log('datetime', inputValue)
		}

		// if (e.target.id == "architectId") {
		// 	console.log("i am archId");
		// 	//projectData[e.target.name] = Number(e.target.value);
		// 	inputValue = parseInt(e.target.value);
		// }
		// if (e.target.id == "projectManagerId") {
		// 	console.log("i am pm");
		// 	//projectData[e.target.name] = Number(e.target.value);
		// 	inputValue = parseInt(e.target.value);
		// }

		const updatedStore = { ...storeDetailsStateUpdated, [e.target.name]: inputValue }
		console.log('updatedStore', updatedStore)
		setStoreDetailsState(updatedStore);
	}

	const history = useHistory();
	// function handleClickAddMaterial() {
	// 	history.push('/designer/projects/material');
	// }

	const cleanStoreDataForDb = (storeData) => {
		var storeDataClean = {...storeData}
		// delete storeDataClean["createdAt"]
		// storeDataClean.openingDate = new Date(storeDataClean.openingDate) // DATETIME db field

		storeDataClean = Store.setDesigner(storeDataClean.scorecardType, userId, storeDataClean)
		storeDataClean.creatorId = Number(storeDataClean.creatorId)
		storeDataClean.designerIdConcept = Number(storeDataClean.designerIdConcept)
		storeDataClean.designerIdAsBuilt = Number(storeDataClean.designerIdAsBuilt)
		storeDataClean.designerIdDecom = Number(storeDataClean.designerIdDecom)

		return storeDataClean
	}

	const saveProjectData = () => {
		createStore(cleanStoreDataForDb(storeDetailsState), (store) => {
			const storeIdNew = store.id
			// dispatch(setStoreId(storeId))
			// dispatch(setStoreId(storeId))
			history.push(makeStorePathMaterials(storeIdNew))
			setStore(store)
		})
	}

	const updateProjectData = () => {
		updateStore(cleanStoreDataForDb(storeDetailsState), (store) => {
			history.push(makeStorePathMaterials(store.id))
			setStore(store)
		})
	}

	const handleSubmit = (event) => {
		const form = event.currentTarget;

		setStoreDetailsState({ ...storeDetailsState, ready: "ready" });

		if (form.checkValidity() == false) {
			// console.log("false");
			event.preventDefault();
			event.stopPropagation();
		}
		else if (form.checkValidity() == true) {
			event.preventDefault();
			event.stopPropagation();
			// console.log("true");
			if (editMode) {
				updateProjectData();
			} else {
				saveProjectData();
			}
			
		}

		setValidated(true);
	};


	// console.log("storeDetailsState");
	// console.log(storeDetailsState);

	const renderAdminFields = () => {

		if (AccessControl.isAdmin(user) === false) {
			return undefined
		}

		return (
			<>
				<Row className="py-3">
					<h4 className="border-bottom border-dark">Admin</h4>
				</Row>

				<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
					<Form.Label column md={4}>
						Creator
					</Form.Label>
					<Col md={8}>
						<Form.Select
							required
							value={storeDetailsState.creatorId || ""} // if edit mode, load in value, if not, use default
							id="creatorId"
							name="creatorId"
							onChange={onChange}>
							<option value="" disabled="{true}">Please Select</option>
							{userList.map((m) => (
								(m.id === userId) ?
									<option selected value={m.id}>{m.email}</option>
									:
									<option value={m.id}>{m.email}</option>

							))}
						</Form.Select>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
					<Form.Label column md={4}>
						Concept Designer
					</Form.Label>
					<Col md={8}>
						<Form.Select
							// required
							value={storeDetailsState.designerIdConcept || ""} // if edit mode, load in value, if not, use default
							id="designerIdConcept"
							name="designerIdConcept"
							onChange={onChange}>
							<option value="" disabled="{true}">Please Select</option>
							{userList.map((m) => (
								(m.id === userId) ?
									<option selected value={m.id}>{m.email}</option>
									:
									<option value={m.id}>{m.email}</option>

							))}
						</Form.Select>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
					<Form.Label column md={4}>
						As-Built Designer
					</Form.Label>
					<Col md={8}>
						<Form.Select
							// required
							value={storeDetailsState.designerIdAsBuilt || ""} // if edit mode, load in value, if not, use default
							id="designerIdAsBuilt"
							name="designerIdAsBuilt"
							onChange={onChange}>
							<option value="" disabled="{true}">Please Select</option>
							{userList.map((m) => (
								(m.id === userId) ?
									<option selected value={m.id}>{m.email}</option>
									:
									<option value={m.id}>{m.email}</option>

							))}
						</Form.Select>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
					<Form.Label column md={4}>
						Decommission Designer
					</Form.Label>
					<Col md={8}>
						<Form.Select
							// required
							value={storeDetailsState.designerIdDecom || ""} // if edit mode, load in value, if not, use default
							id="designerIdDecom"
							name="designerIdDecom"
							onChange={onChange}>
							<option value="" disabled="{true}">Please Select</option>
							{userList.map((m) => (
								(m.id === userId) ?
									<option selected value={m.id}>{m.email}</option>
									:
									<option value={m.id}>{m.email}</option>

							))}
						</Form.Select>
					</Col>
				</Form.Group>

				
			</>
		)
	}

	const renderForm = () => {
		return (
		<Form className="col-md-12" autoComplete="off" validated={validated} noValidate onSubmit={handleSubmit}>
			{editMode ? undefined : (
				<Row>
					<Col md={12} className="pt-5 pb-3">
						<h4>New Store</h4>
						{/* for testing purposes */}
						{/* editmode = {String(editMode)} storeId = {String(storeId)} */} 
					</Col>
				</Row>
			)}

				{/*<Row className="g-5 my-2">
					<Col md>
						<FloatingLabel controlId="floatingInputGrid" label="Store Name">
							<Form.Control
								id="storeName"
								name="storeName"
								type="number"
								placeholder="Store Name"
								onChange={onChange}
							/>
						</FloatingLabel>
					</Col>
					<Col md>
						<FloatingLabel controlId="floatingSelectGrid" label="Scorecard Type">
							<Form.Select id="scorecardType" name="scorecardType" onChange={onChange}>
								<option selected disabled={true}>Please Select</option>
								{scorecardType.map((m) => (
									<option key={m.id} value={m.value}>{m.name}</option>
								))}
							</Form.Select>
						</FloatingLabel>
					</Col>
				</Row>*/}

				<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
					<Form.Label column md={2}>
						Store Name
					</Form.Label>
					<Col md={10}>
						<Form.Control
							required
							value={storeDetailsState.storeName || ""} // if edit mode, load in value, if not, use default
							id="storeName"
							name="storeName"
							type="text"
							placeholder="Store Name"
							onChange={onChange}
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
					{/* <Form.Label column md={2}>
						Store Code
					</Form.Label>
					<Col md={4}>
						<Form.Control
							required
							value={storeDetailsState.storeCode || ""} // if edit mode, load in value, if not, use default
							id="storeCode"
							name="storeCode"
							type="text"
							placeholder="Store Code"
							onChange={onChange}
						/>
					</Col> */}
					<Form.Label column md={2}>
						Scorecard Type
					</Form.Label>
					<Col md={4}>
						<Form.Select
							required
							value={storeDetailsState.scorecardType || ""} // if edit mode, load in value, if not, use default
							id="scorecardType"
							name="scorecardType"
							onChange={onChange}>
							<option selected value="" disabled="{true}">Please Select</option>
							{scorecardTypes.map((m) => (
								<option key={m.id} value={m.name}>{m.title}</option>
							))}
						</Form.Select>
					</Col>
				{/* </Form.Group> */}

				{/* <Form.Group as={Row} className="mb-md-3 d-flex align-items-center"> */}
					<Form.Label column md={2}>
						Location Type
					</Form.Label>
					<Col md={4}>
						<Form.Select
							required
							value={storeDetailsState.locationType || ""} // if edit mode, load in value, if not, use default
							id="locationType"
							name="locationType"
							onChange={onChange}>
							<option selected value="" disabled="{true}">Please Select</option>
							{locationType.map((m) => (
								<option key={m.id} value={m.name}>{m.name}</option>
							))}
						</Form.Select>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
					<Form.Label column md={2}>
						Street Address
					</Form.Label>
					<Col md={10}>
						<Form.Control
							required
							value={storeDetailsState.streetAddress || ""} // if edit mode, load in value, if not, use default
							id="streetAddress"
							name="streetAddress"
							type="text"
							placeholder="Street Address"
							// placeholder="The full physical address"
							onChange={onChange}
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
					<Form.Label column md={2}>
						Region
					</Form.Label>
					<Col md={4}>
						<Form.Select
							required
							value={storeDetailsState.region || ""} // if edit mode, load in value, if not, use default
							id="region"
							name="region"
							onChange={onChange}>
							<option selected value="" disabled={true}>Please Select</option>
							{regionList.map(r => (
								<option key={r.id} value={r.name}>{r.name}</option>
							))}
						</Form.Select>
					</Col>
					<Form.Label column md={2}>
						Country
					</Form.Label>
					<Col md={4}>
						<Form.Select
							disabled={countryListFiltered.length === 0}
							title={countryListFiltered.length === 0 ? "Select a region from the Region dropdown in order to show countries" : "Select a country from the list"}
							required
							value={storeDetailsState.country || ""} // if edit mode, load in value, if not, use default
							id="country"
							name="country"
							onChange={onChange}>
							<option selected value="" disabled={true}>{countryListFiltered.length === 0 ? "Select Region" : "Select Country"}</option>
							{countryListFiltered.map((m) => (
								<option key={m.code} value={m.name}>{m.name}</option>
							))}
						</Form.Select>
					</Col>
				</Form.Group>
				
				<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
					<Form.Label column md={2}>
						City
					</Form.Label>
					<Col md={4}>
						<Form.Select
							disabled={cityListFiltered.length === 0}
							title={cityListFiltered.length === 0 ? "Select a country from the Country dropdown in order to show cities" : "Select a city from the list"}
							required
							value={storeDetailsState.city || ""} // if edit mode, load in value, if not, use default
							id="city"
							name="city"
							onChange={onChange}>
							<option value="" disabled={true}>{cityListFiltered.length === 0 ? "Select Country" : "Select City"}</option>
							{cityListFiltered.map((c) => (
								// <option key={c.key} value={c.name}>{c.name}</option>
								<option key={c} value={c}>{c}</option>
							))}
						</Form.Select>
					</Col>

					<Form.Label column md={2}>
						Opening Date
					</Form.Label>
					<Col md={4}>
						<Form.Control
							value={storeDetailsState.openingDate && jsDateTimeToDateStr(storeDetailsState.openingDate) || null} // if edit mode, load in value, if not, use default
							id="openingDate"
							name="openingDate"
							type="date"
							onChange={onChange}
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
					<Form.Label column md={2}>
						ROIC CAPEX
					</Form.Label>
					<Col md={4}>
						<Form.Control
							id="roicCapex"
							value={storeDetailsState.roicCapex || null} // if edit mode, load in value, if not, use default
							name="roicCapex"
							type="number"
							placeholder="ROIC CAPEX"
							min="0"
							onChange={onChange}
						/>
					</Col>
					<Form.Label column md={2}>
						Currency
					</Form.Label>
					<Col md={4}>
						<Form.Select
							required
							value={storeDetailsState.currency || ""} // if edit mode, load in value, if not, use default
							id="currency"
							name="currency"
							onChange={onChange}>
							<option selected value="" disabled="{true}">Please Select</option>
							{currencyList.map((m) => (
								<option key={m.code} value={m.id}>{m.id}</option>
							))}
						</Form.Select>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
					<Form.Label column md={2}>
						Total Sqm
					</Form.Label>
					<Col md={4}>
						<Form.Control
						value={storeDetailsState.totalSqm || null} // if edit mode, load in value, if not, use default
							id="totalSqm"
							name="totalSqm"
							type="number"
							placeholder="Total Sqm"
							min="0"
							onChange={onChange}
						/>
					</Col>
					<Form.Label column md={2}>
						Retail Sqm
					</Form.Label>
					<Col md={4}>
						<Form.Control
							required
							value={storeDetailsState.retailSqm || null} // if edit mode, load in value, if not, use default
							id="retailSqm"
							name="retailSqm"
							type="number"
							placeholder="Retail Sqm"
							title="FOH + Treatment Space"
							min="0"
							onChange={onChange}
						/>
					</Col>
				</Form.Group>

				{/* Admin Only --------------------------------------------------------------------------------------------------- */}

				{renderAdminFields()}

				{/* --------------------------------------------------------------------------------------------------------------- */}

				{/* <Row>
					<Col md={12} className="pt-5 pb-3">
						<h4>Detailed Store Data</h4>
					</Col>
				</Row> */}
				<Row className="py-3">
					<h4 className="border-bottom border-dark">Detailed Store Data</h4>
				</Row>

				<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
					<Form.Label column md={2}>
						Lease terms
					</Form.Label>
					<Col md={4}>
						<Form.Control
							id="leaseTermInYears"
							value={storeDetailsState.leaseTermInYears || null} // if edit mode, load in value, if not, use default
							name="leaseTermInYears"
							type="number"
							placeholder="No. of years"
							min="0"
							onChange={onChange}
						/>
					</Col>
					<Form.Label column md={2}>
						Project Manager
					</Form.Label>
					<Col md={4}>
						{/* <Form.Select
							required
							value={storeDetailsState.projectManagerId || ""} // if edit mode, load in value, if not, use default
							id="projectManagerId"
							name="projectManagerId"
							onChange={onChange}>
							<option value="" disabled="{true}">Project Manager</option>
							{usersArchitect.map((m) => (
								(m.id === userId) ?
									<option selected value={m.id}>{m.email}</option>
									:
									<option value={m.id}>{m.email}</option>

							))}
						</Form.Select> */}
						<Form.Control
							id="projectManager"
							value={storeDetailsState.projectManager || ""} // if edit mode, load in value, if not, use default
							name="projectManager"
							type="text"
							placeholder="name@aesop.com"
							onChange={onChange}
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
					<Form.Label column md={2}>
						Architect
					</Form.Label>
					<Col md={4}>
						{/* <Form.Select
							required
							value={storeDetailsState.architectId || ""} // if edit mode, load in value, if not, use default
							id="architectId"
							name="architectId"
							onChange={onChange}>
							<option selected value="" disabled="{true}">Please Select</option>
							{usersArchitect.map((m) => (
								<option value={m.id}>{m.email}</option>
							))}
						</Form.Select> */}
						<Form.Control
							id="architect"
							value={storeDetailsState.architect || ""} // if edit mode, load in value, if not, use default
							name="architect"
							type="text"
							placeholder="name@website.com"
							onChange={onChange}
						/>
					</Col>
					<Form.Label column md={2}>
						Contractor
					</Form.Label>
					<Col md={4}>
						<Form.Control
							id="contractorId"
							value={storeDetailsState.contractorId || ""} // if edit mode, load in value, if not, use default
							name="contractorId"
							type="text"
							placeholder="Contractor"
							onChange={onChange}
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-md-3d-flex align-items-center">
					<Form.Label column xs={6} md={4} className="my-2">
						Signed Supplier Agreement
					</Form.Label>
					<Col xs={6} md={2}>
						<Form.Check
							custom
							checked={storeDetailsState.signedCoc || null} // if edit mode, load in value, if not, use default
							className="align-self-center"
							type="switch"
							name="signedCoc"
							id="signedCoc"
							onChange={onChange}
						/>
					</Col>
					<Form.Label column xs={6} md={4} className="my-2">
						Signed NDA
					</Form.Label>
					<Col xs={6} md={2}>
						<Form.Check
							custom
							checked={storeDetailsState.signedNda || null} // if edit mode, load in value, if not, use default
							className="align-self-center"
							type="switch"
							name="signedNda"
							id="signedNda"
							onChange={onChange}
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
					<Form.Label column md={2}>
						BOH Sqm
					</Form.Label>
					<Col md={4}>
						<span
							id="bohSqm"
							className="bg-alabaster"
							disabled={true}
							// value={storeDetailsState.bohSqm || null} // if edit mode, load in value, if not, use default
							value={calcBohSqm(storeDetailsState)} // if edit mode, load in value, if not, use default
							name="bohSqm"
							type="number"
							placeholder="Enter Total and Retail Areas first"
							title="Calculated: BOH Sqm = Total Sqm - Retail Sqm"
							min="0"
							// onChange={onChange}
						>{calcBohSqm(storeDetailsState)}</span>
					</Col>

					<Form.Label column md={2} className="my-2">
						Treatment Space
					</Form.Label>
					<Col md={4}>
						{/* <Form.Check
							custom
							checked={storeDetailsState.treatmentSpace || null} // if edit mode, load in value, if not, use default
							className="align-self-center"
							type="switch"
							name="treatmentSpace"
							id="treatmentSpace"
							onChange={onChange}
						/> */}
						<Form.Control
							id="treatmentSpace"
							value={storeDetailsState.treatmentSpace || null} // if edit mode, load in value, if not, use default
							name="treatmentSpace"
							type="number"
							placeholder="Treatment Space Sqm"
							min="0"
							onChange={onChange}
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
				<Form.Label column md={2}>
						Marketing Media Type
					</Form.Label>
					<Col md={4}>
						<Form.Select
							id="marketingMediaType"
							value={storeDetailsState.marketingMediaType || ""} // if edit mode, load in value, if not, use default
							name="marketingMediaType"
							onChange={onChange}>
							<option selected value="" disabled="{true}">Please Select</option>
							{marketingMediaType.map((m) => (
								<option key={m.id} value={m.name}>{m.name}</option>
							))}
						</Form.Select>
					</Col>

					<Form.Label column md={2}>
						POS Number
					</Form.Label>
					<Col md={4}>
						<Form.Control
							id="pos"
							value={storeDetailsState.pos || null} // if edit mode, load in value, if not, use default
							name="pos"
							type="number"
							placeholder="POS number"
							min="0"
							onChange={onChange}
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
					<Form.Label column md={2}>
						MPOS Number
					</Form.Label>
					<Col md={4}>
						<Form.Control
							id="mpos"
							value={storeDetailsState.mpos || null} // if edit mode, load in value, if not, use default
							name="mpos"
							type="number"
							placeholder="MPOS number"
							min="0"
							onChange={onChange}
						/>
					</Col>

					<Form.Label column md={2}>
						Tap Number
					</Form.Label>
					<Col md={4}>
						<Form.Control
							id="tapNumber"
							value={storeDetailsState.tapNumber || null} // if edit mode, load in value, if not, use default
							name="tapNumber"
							type="number"
							placeholder="No. of taps"
							min="0"
							onChange={onChange}
						/>
					</Col>
					
				</Form.Group>

				<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
					<Form.Label column xs={6} md={4} className="my-2">
						Bathroom
					</Form.Label>
					<Col xs={6} md={2}>
						<Form.Check
							custom
							checked={storeDetailsState.bathroom || null} // if edit mode, load in value, if not, use default
							className="align-self-center"
							type="switch"
							name="bathroom"
							id="bathroom"
							onChange={onChange}
						/>
					</Col>
					<Form.Label column md={2} className="my-2">
						Other Space
					</Form.Label>
					<Col md={4}>
						<Form.Control
							id="otherSpace"
							checked={storeDetailsState.otherSpace || ""} // if edit mode, load in value, if not, use default
							name="otherSpace"
							type="text"
							placeholder="Other space(s)"
							onChange={onChange}
						/>
					</Col>
				</Form.Group>

				{/* <Row>
					<Col md={12} className="pt-5 pb-3">
						<h4>Sustainability Initiatives</h4>
						<small className="text-muted">Please check all options that are applicable</small>
					</Col>
				</Row> */}

				<Row className="py-3">
					<h4 className="border-bottom border-dark">Sustainability Initiatives</h4>
					<small className="text-muted">Please check all options that are applicable</small>
				</Row>

				<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
					<Form.Label column xs={6} md={4} className="my-2">
						Greenspace
					</Form.Label>
					<Col xs={6} md={2}>
						<Form.Check
							custom
							checked={storeDetailsState.susInitGreenspace || null} // if edit mode, load in value, if not, use default
							className="align-self-center"
							type="switch"
							name="susInitGreenspace"
							id="susInitGreenspace"
							onChange={onChange}
						/>
					</Col>
					<Form.Label column xs={6} md={4} className="my-2">
						Rainwater Storage and Reuse
					</Form.Label>
					<Col xs={6} md={2}>
						<Form.Check
							custom
							checked={storeDetailsState.susInitRainwater || null} // if edit mode, load in value, if not, use default
							className="align-self-center"
							type="switch"
							name="susInitRainwater"
							id="susInitRainwater"
							onChange={onChange}
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
					<Form.Label column xs={6} md={4} className="my-2">
						Solar PV Panels
					</Form.Label>
					<Col xs={6} md={2}>
						<Form.Check
							custom
							checked={storeDetailsState.susInitSolarPV || null} // if edit mode, load in value, if not, use default
							className="align-self-center"
							type="switch"
							name="susInitSolarPV"
							id="susInitSolarPV"
							onChange={onChange}
						/>
					</Col>
					<Form.Label column xs={6} md={4} className="my-2">
						Greywater Treatment and Reuse
					</Form.Label>
					<Col xs={6} md={2}>
						<Form.Check
							custom
							checked={storeDetailsState.susInitGreywater || null} // if edit mode, load in value, if not, use default
							className="align-self-center"
							type="switch"
							name="susInitGreywater"
							id="susInitGreywater"
							onChange={onChange}
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
					<Form.Label column xs={6} md={4} className="my-2">
						Battery
					</Form.Label>
					<Col xs={6} md={2}>
						<Form.Check
							custom
							checked={storeDetailsState.susInitBattery || null} // if edit mode, load in value, if not, use default
							className="align-self-center"
							type="switch"
							name="susInitBattery"
							id="susInitBattery"
							onChange={onChange}
						/>
					</Col>
					<Form.Label column xs={6} md={4} className="my-2">
						Mechanical Ventilation
					</Form.Label>
					<Col xs={6} md={2}>
						<Form.Check
							custom
							checked={storeDetailsState.susInitVentilation || null} // if edit mode, load in value, if not, use default
							className="align-self-center"
							type="switch"
							name="susInitVentilation"
							id="susInitVentilation"
							onChange={onChange}
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-md-3 d-flex align-items-center">
					<Form.Label column xs={6} md={4} className="my-2">
						Composting
					</Form.Label>
					<Col xs={6} md={2}>
						<Form.Check
							custom
							checked={storeDetailsState.susInitComposting || null} // if edit mode, load in value, if not, use default
							className="align-self-center"
							type="switch"
							name="susInitComposting"
							id="susInitComposting"
							onChange={onChange}
						/>
					</Col>
				</Form.Group>

				<Row className="mb-5">
					<Col xs={12} className="d-flex align-items-end justify-content-end mt-2">
						<Button
							type="submit"
							variant="outline-dark"
							size="lg"
						//onClick={saveProjectData}
						>
							Next
							<i className="bi bi-chevron-right mx-2"></i>
						</Button>
					</Col>
				</Row>
			</Form>
		)
	}

	if (editMode) {
		// Edit store
		return (
			// Return a list to be consistent with the other store tabs content
			<>
				<Row className="py-3">
					<h4 className="border-bottom border-dark">{editMode ? "Edit Store" : "New Store"}</h4>
				</Row>
				{renderForm()}
			</>
		)
	} else {
		// Create new
		return (
			<Container>
				<Row>
					<Header />
				</Row>
				
				{renderForm()}
			</Container >
		)
	}

}

export default ProjectCreateNew;