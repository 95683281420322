import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import axios from 'axios'
import Header from "./Header"
import Container from 'react-bootstrap/Container'
import Dropdown from 'react-bootstrap/Dropdown'
import Modal from 'react-bootstrap/Modal';
import Toast from 'react-bootstrap/Toast';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
// import Accordion from 'react-bootstrap/Accordion'
import { makeStorePathView, makeStorePathEdit, makeStorePathMaterials } from './ProjectSummaryPage'
import { formatScore, formatGhge } from '../HelperFunctions'
import { config } from '../config'
import { DbObj, DbObjStatus, loadUsers } from "./AdminUsersPage"
import { UserRole } from "../AccessControl"

export const Store = {
    ScorecardType: {
        CONCEPT: 'CONCEPT',
        AS_BUILT: 'AS_BUILT',
        DECOMMISSIONED: 'DECOMMISSIONED',
    },
    Status: {
        DRAFT: 'DRAFT',
        AWAITING_REVIEW: 'AWAITING_REVIEW',
        REVIEWED: 'REVIEWED',
        DELETED: 'DELETED',
    },

    getStatusTitle: (status) => {   
        const st = statusList.find(st => st.name === status)
        return st ? st.title : ""
    },

    /** Get the scorecardType title from the name */
    getScorecardTypeTitle: (scorecardType) => {
        const st = scorecardTypes.find(st => st.name === scorecardType)
        return st ? st.title : ""
    },

    /** Get the scorecardType next in the sequence */
    getScorecardTypeInfoNext: (scorecardType) => {
        const index = scorecardTypes.findIndex(st => st.name === scorecardType)
        const indexNext = index + 1

        if (index === -1 || indexNext >= scorecardTypes.length) {
            return undefined
        } else {
            return scorecardTypes[indexNext]
        }
    },

    /** Get the designer for the given scorecardType */
    getDesignerId: (scorecardType, store) => {

        if (scorecardType === Store.ScorecardType.CONCEPT) {
			return store.designerIdConcept
		} else if (scorecardType === Store.ScorecardType.AS_BUILT) {
			return store.designerIdAsBuilt
		} else if (scorecardType === Store.ScorecardType.DECOMMISSIONED) {
			return store.designerIdDecom
		}

        return undefined
    },

    /** Get the designer for the current store scorecardType */
    getCurrentDesignerId: (store) => {
        // console.log('getCurrentDesignerId', store)
        return Store.getDesignerId(store.scorecardType, store)
    },

    /** Set the designer for a given scorecardType */
    setDesigner: (scorecardType, designerUserId, store) => {
        const storeDataClean = { ...store }

        if (scorecardType === Store.ScorecardType.CONCEPT) {
			storeDataClean.designerIdConcept = designerUserId
		} else if (scorecardType === Store.ScorecardType.AS_BUILT) {
			storeDataClean.DesignerIdAsBuilt = designerUserId
		} else if (scorecardType === Store.ScorecardType.DECOMMISSIONED) {
			storeDataClean.DesignerIdDecom = designerUserId
		}

        return storeDataClean
    }
}

const statusList = [
	{ title: "Draft", name: Store.Status.DRAFT },
	{ title: "Awaiting Review", name: Store.Status.AWAITING_REVIEW },
	{ title: "Reviewed", name: Store.Status.REVIEWED },
	{ title: "Deleted", name: Store.Status.DELETED },
];

export const scorecardTypes = [
	{ id: 1, title: "Concept", name: Store.ScorecardType.CONCEPT },
	{ id: 2, title: "As-Built", name: Store.ScorecardType.AS_BUILT },
	{ id: 3, title: "Decommissioned", name: Store.ScorecardType.DECOMMISSIONED },
];

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // for smoothly scrolling
   });
}

export const loadAllStores = (setStoreList) => {
    axios
        .get(`${config.backendUrl}/stores`)
        .then(response => response.data)
        .then(stores => {
            setStoreList(stores)
        })
}

const cleanStoreDataForDb = (storeData) => {
    const clean = {...storeData}
    delete clean["createdAt"]
    clean.openingDate = new Date(clean.openingDate) // DATETIME db field
    return clean
}

export const loadStore = (storeId, onSuccess, onError) => {
    axios.get(`${config.backendUrl}/stores/${storeId}`)
        .then(function (response) {
            onSuccess(response.data)
        })
        .catch(response => {
            console.log('Error loading store', storeId, response)
            onError && onError(response && response.data)
        }) 
}

export const createStore = (store, onSuccess) => {
    axios.post(`${config.backendUrl}/store`, cleanStoreDataForDb(store))
        .then(function (response) {
            // console.log(response);
            onSuccess(response.data)
        })
        .catch(function (error) {
            console.log(error);
        })
}

export const updateStore = (store, onSuccess, onError) => {
    // console.log('updateStore', store)
    axios.put(`${config.backendUrl}/stores/${store.id}`, cleanStoreDataForDb(store))
        .then(function (response) {
            // console.log(response)
            onSuccess(response.data)
        })
        .catch(function (response) {
            console.error('Error updating store', response && response.data)
            onError && onError(response && response.data)
        })
}

export const getStoreScoreForScorecardType = (storeId, scorecardType, onSuccess, onError) => {
    axios.get(`${config.backendUrl}/stores/${storeId}/scores/${scorecardType}`)
        .then(function (response) {
            onSuccess(response.data)
        })
        .catch(response => {
            console.log('Error loading store', storeId, response)
            onError && onError(response && response.data)
        }) 
}

export const copyStoreMaterialsFromScorecardType = (storeId, scorecardType, onSuccess, onError) => {
    axios.get(`${config.backendUrl}/stores/${storeId}/copy-materials/${scorecardType}`)
        .then(function (response) {
            onSuccess(response.data)
        })
        .catch(response => {
            // show user the error message
            console.log('Error loading store', storeId, response)
            onError && onError(response && response.data)
        }) 
}

export const AdminStoresPage = (props) => {
    const [storeList, setStoreList] = useState([])
	const [usersAll, setUsersAll] = useState([])
	const [users, setUsers] = useState([])
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
    const [nameOfStoreToDelete, setNameOfStoreToDelete] = useState("")
    const [showAdminToastMsg, setShowAdminToastMsg] = useState(false)
    const [adminToastMsg, setAdminToastMsg] = useState("")
    const [currentStore, setCurrentStore] = useState(undefined)

    const history = useHistory()

    const loadAllStoresAndSetState = () => {
        loadAllStores(stores => {
            setStoreList(stores.map(DbObj.ofDb))
        })
    }

    const recalcStoreScore = (storeId) => {
        axios
            .get(`${config.backendUrl}/stores/recalc/${storeId}`)
            .then(response => loadAllStoresAndSetState())
    }

    useEffect(() => {
        loadAllStoresAndSetState()
        loadUsers(users => {
            setUsersAll(users)
            setUsers(users.filter(u => u.role !== UserRole.Admin))
        })
    }, [])

    const findUserById = userId => usersAll.find(user => user.id === userId)

    /** Update a store in the local state */
    const setStore = (updatedStore) => {
        const updatedStores = storeList.map(s => {
            if (s.id === updatedStore.id) {
                return { ...updatedStore }
            } else {
                return s
            }
        })

        console.log('updatedStores', updatedStores)
        setStoreList(updatedStores)
    }

    // const onStoreStatusChange = (e) => {
    //     const newStatus = e.target.value
    //     console.log("onStoreStatusChange", e)
    // }

    const handleStoreActionClick = (store, actionName) => {
        if (actionName === "VIEW") {
            const path = makeStorePathView(store.id)
            history.push(path);
            scrollToTop()
        } else if (actionName === "EDIT") {
            // const path = makeStorePathEdit(store.id)
            // history.push(path);
            // scrollToTop()
            setStore({ ...store, _objStatus: DbObjStatus.CHANGED})
        } else if (actionName === "ADD_MATERIALS") {
            const path = makeStorePathMaterials(store.id)
            history.push(path);
            scrollToTop()
        } else if (actionName === "DELETE") {
            // Set storeStatus to "DELETE"
        } else if (actionName === "DELETE_PERMANENTLY") {
            setNameOfStoreToDelete("")
            setCurrentStore(store)
            setShowDeleteConfirmModal(true)
        } else if (actionName === "RECALC_SCORE") {
            recalcStoreScore(store.id)
        }
    }
    
    const renderStoreRowActions = (store) => {
        return (
            <Dropdown>
              <Dropdown.Toggle size="sm" variant="outline-dark" id="dropdown-basic">
              </Dropdown.Toggle>
        
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleStoreActionClick(store, "VIEW")}>View</Dropdown.Item>
                <Dropdown.Item onClick={() => handleStoreActionClick(store, "EDIT")}>Edit</Dropdown.Item>
                <Dropdown.Item onClick={() => handleStoreActionClick(store, "ADD_MATERIALS")}>Add Materials</Dropdown.Item>
                {/* <Dropdown.Item onClick={() => handleStoreActionClick(store, "DELETE")}>Delete</Dropdown.Item> */}
                <Dropdown.Item onClick={() => handleStoreActionClick(store, "DELETE_PERMANENTLY")}>Permanently Delete</Dropdown.Item>
                <Dropdown.Item onClick={() => handleStoreActionClick(store, "RECALC_SCORE")}>Recalculate Score</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          );
    }

    const handleDeleteConfirmModelClose = () => { setShowDeleteConfirmModal(false) }

    const showToastMsg = (msg) => {
        setAdminToastMsg(msg)
        setShowAdminToastMsg(true)
    }

    const handleDeleteConfirmModelDelete = () => {
        setShowDeleteConfirmModal(false)
        scrollToTop()

        // console.log("Deleting store with id", currentStore.id, currentStore.storeName)

        axios.delete(`${config.backendUrl}/stores/${currentStore.id}`)
			.then(function (response) {
				// var data = response.data
				// console.log("store delete response", data)
                showToastMsg("Store deleted (" + currentStore.storeName + ")")
                setNameOfStoreToDelete("")
                loadAllStoresAndSetState()
			})
            .catch(() => {
                showToastMsg("Error deleting store (" + currentStore.storeName + "). Please try again.")
            })
    }

    const handleStoreCreatorChange = (e, store) => {
        const userId = e.target.value
        console.log('store creator changing to', userId)

        const uStore = {
            ...store,
            creatorId: Number(userId)
        }

        // TODO: show toast message
        updateStore(uStore, () => loadAllStoresAndSetState())
    }

    return (
        <Container>
            <Row className="row h-10">
                <Header />
            </Row>

            <Row className="row h-90 p-2">
                <h3>Stores</h3>
            </Row>

            {/* Admin toast message */}
            <Toast show={showAdminToastMsg} onClose={() => setShowAdminToastMsg(false)}>
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">Admin Notification</strong>
                    {/* <small>{adminToastMsg}</small> */}
                </Toast.Header>
                <Toast.Body>{adminToastMsg}</Toast.Body>
            </Toast>

            {/* Delete confirmation modal */}
            <Modal show={showDeleteConfirmModal} onHide={handleDeleteConfirmModelClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Store Delete</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                    <p>Are you sure you want to delete this store?</p>
                    <h4>{currentStore && currentStore.storeName}</h4>
                    <br/>
                    <p>To confirm, please enter store name below</p>
                    <Form.Control type="text" onChange={e => setNameOfStoreToDelete(e.target.value)} value={nameOfStoreToDelete}></Form.Control>
                </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="light" onClick={handleDeleteConfirmModelClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" disabled={currentStore && currentStore.storeName.trim() !== nameOfStoreToDelete.trim()} onClick={handleDeleteConfirmModelDelete}>
                        Permanently Delete Store
                    </Button>
                </Modal.Footer>
            </Modal>

            <Row className="row h-90 p-2">
                <Table className="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Creator</th>
                            <th>Designer</th>
                            <th>Country</th>
                            {/* <th>Region</th> */}
                            <th>Scorecard</th>
                            {/* <th>Location Type</th> */}
                            {/* <th>Review Required</th> */}
                            <th>Status</th>
                            <th title="GHG Emissions Score ((kgCO2e/sqm)">GHG</th>
                            <th title="Total Score">Circularity Score</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {storeList.map((store) => {
                            const userCreator = findUserById(store.creatorId)
                            const userDesignerId = Store.getCurrentDesignerId(store)
                            const userDesigner = userDesignerId && findUserById(userDesignerId)

                            return (
                                <tr key={store.id} className="align-middle">
                                    <td><Link to={`/stores/${store.id}/view`}>{store.storeName}</Link></td>
                                    {store._objStatus === DbObjStatus.DB ? (
                                        <td>{userCreator && userCreator.displayName}</td>
                                    ) : (
                                        <td>
                                            <Form.Select className="bg-white p-1" value={(userCreator && userCreator.id) || ""} onChange={e => handleStoreCreatorChange(e, store)}>
                                                <option key="empty" value="" disabled={true}>Select a user</option>
                                                {users.map(u => (
                                                    <option key={u.id} value={u.id}>{u.displayName}</option>
                                                ))}
                                           </Form.Select>
                                        </td>
                                    )}
                                    <td>{userDesigner && userDesigner.displayName}</td>
                                    <td>{store.country}</td>
                                    {/* <td>{store.region}</td> */}
                                    <td>{store.scorecardType}</td>
                                    {/* <td>{store.locationType}</td> */}
                                    {/* {store.reviewRequired ?
                                        <td><i className="bi bi-exclamation-circle"></i></td> :
                                        <td></td>
                                    } */}
                                    <td>{store.storeStatus}</td>
                                    <td>{formatGhge(store.GHGEmissions)}</td>
                                    <td>{formatScore(store.totalScore)}</td>
                                    {/* <td>
                                        <select
                                            value={store.storeStatus}
                                            onChange={onStoreStatusChange}
                                            >
                                            {storeStatusItems.map(item => 
                                                <option>{item}</option>
                                                )
                                            }
                                        </select>
                                    </td> */}
                                    <td>
                                        {renderStoreRowActions(store)}
                                    </td>
                                </tr>
                            )
                        })
                        }
                    </tbody>
                </Table>
            </Row>
        </Container>
    );
}