/** To get a unique list of array items */
export const onlyUnique = (value, index, self) => self.indexOf(value) === index

/** String comparer function for sorting a list of objects */
export const sortByFieldAsc = fieldName => (objA, objB) => {
	let a = objA[fieldName].toLowerCase()
	let b = objB[fieldName].toLowerCase()

	if (a < b) {
		return -1
	} else if (a > b) {
		return 1
	} else {
		return 0
	}
}

/** Make sure a value is a string, if undefined then return empty string */
export const ensureString = (maybeStr) => {
	if (maybeStr === 0) {
		return "0"
	} else if (maybeStr) {
		return String(maybeStr)
	} else {
		return ""
	}
}

export const formatScore = (score) => {
	if (score === 0) {
		return "0"
	} else if (!score) {
		return ""
	} else if (isNaN(score) === false) {
		return Number(score).toFixed(0)
	} else {
		console.error("Could not understand score =", score)
		return ""
	}
}

export const formatGhge = formatScore

export const formatPercentage = (perc) => {
	if (!perc) {
		return "0"
	} else if (perc === 0) {
		return 0
	} else if (isNaN(perc) === false) {
		// Math.round(perc * 100) / 100
		return Number(perc).toFixed(1)
	} else {
		console.error("Could not understand perc =", perc)
		return ""
	}
}

/** Format relative improvement score from decommission scorecard */
export const formatRelImprov = (ratio) => {
	return formatPercentage(ratio)
}

/** Make superscripts look good */
export const formatUnit = (unit) => {
	const formatted =
		unit
		.replace("^2", "²")
		.replace("^3", "³")
	
	return formatted
}

/** Make superscripts look good but show nothing when unit is "number" */
export const formatUnitForDisplay = (unit) => {
	if (unit === "number") {
		return ""
	} else {
		return formatUnit(unit)
	}
}

/** Calculate the total GHGE in kg and provide some additional info */
export const calcTotalGhge = (matInst) => {
	const totGhge = matInst.materialQuantity * matInst.embodiedGHGE
	const totGhgeFormatted = formatGhge(totGhge)

	if (matInst.rawMaterial === "Retained" || matInst.rawMaterial === "Reused") {
		return {
			value: 0,
			formatted: formatGhge(0),
			display: `${totGhgeFormatted} kgCO2e`,
			calcInfo: `Retained/reused materials have total GHGE = 0\nOriginal GHGE = ${totGhgeFormatted} kgCO2e`
		}
	} else if (!matInst.embodiedGHGE) {
		return {
			value: undefined,
			formatted: "",
			display: `Unknown kgCO2e`,
			calcInfo: "Embodied GHGE info missing in DB for this material"
		}
	} else {
		return {
			value: totGhge,
			formatted: totGhgeFormatted,
			display: `${totGhgeFormatted} kgCO2e`,
			calcInfo: `= Quantity * Embodied GHGE per unit\n= ${matInst.materialQuantity} * ${matInst.embodiedGHGE}`
		}
	}
}