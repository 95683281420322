// PRODUCTION
// Config and shared variables for app

export const config = {
	version: "v1.8.8",
	
	isDebugMode: false,
	backendUrl: "https://arup-aesop-backend-dev.azurewebsites.net",
	// backendUrl: "https://arup-aesop-backend-prod.azurewebsites.net",

	backendRequestConfig: {
		headers: {
			accept: "application/json"
		}
	}
}