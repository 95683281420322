import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut } from 'react-chartjs-2';
import axios from "axios";
import { useSelector } from 'react-redux';
import { scaleColoursSeven, scaleColours, scaleValues } from './GlobalVars'

ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);


function OverviewDonutChart() {

	const backendUrl = useSelector((state) => state.backendUrl);
	const [materialCounts, setMaterialCounts] = useState([]);
	const [materialCountTotal, setMaterialCountTotal] = useState([]);

	useEffect(() => {

		//get global scores
		axios.get(`${backendUrl}/materialCounts`)
			.then(function (response) {

				console.log("material counts = ")
				console.log(response.data)

				// get counters
				setMaterialCounts(response.data.endOfLifeCounters)

				// get total to calculate percentage for visualizer
				setMaterialCountTotal(
					response.data.endOfLifeCounters.fr +
					response.data.endOfLifeCounters.pr +
					response.data.endOfLifeCounters.rp +
					response.data.endOfLifeCounters.ftrad +
					response.data.endOfLifeCounters.atbrr +
					response.data.endOfLifeCounters.tbs +
					response.data.endOfLifeCounters.nr
				)
				//setMaterialCountTotal(121)

			})
			.catch(function (error) {
				console.log(error);
			});
		console.log(materialCountTotal)
		console.log("materialCounts")
		console.log(materialCounts)
	}, []);


	const data = {
		labels: [
			'Fully recyclable',
			'Part recyclable',
			'Recycling program',
			'Finish to remain after decommission',
			'Able to be reused/repurposed',
			'Takeback Scheme',
			'Not recyclable'
		],
		datasets: [
			{
				label: '%',
				data: [
					Math.round((materialCounts.fr / materialCountTotal) * 100),
					Math.round((materialCounts.pr / materialCountTotal) * 100),
					Math.round((materialCounts.rp / materialCountTotal) * 100),
					Math.round((materialCounts.ftrad / materialCountTotal) * 100),
					Math.round((materialCounts.atbrr / materialCountTotal) * 100),
					Math.round((materialCounts.tbs / materialCountTotal) * 100),
					Math.round((materialCounts.nr / materialCountTotal) * 100),
				],

				backgroundColor: [
					scaleColoursSeven[0],
					scaleColoursSeven[1],
					scaleColoursSeven[2],
					scaleColoursSeven[3],
					scaleColoursSeven[4],
					scaleColoursSeven[5],
					scaleColoursSeven[6],
				],
				borderColor: [
					'#FFFFFF',
					'#FFFFFF',
					'#FFFFFF',
					'#FFFFFF',
					'#FFFFFF',
					'#FFFFFF',
					'#FFFFFF',
				],
				borderWidth: 2,
			},
		],
	};

	const options = {
		responsive: true,
		plugins: {
			htmlLegend: {
				// ID of the container to put the legend in
				containerID: 'chartLegend',
			},
			legend: {
				display: false,
				position: 'bottom',
			},
			datalabels: {
				labels: {
					value: {
						color: 'black'
					}
				},
				formatter: (value) => {
					if (value > 0) {
						return value + '%';
					}
					else {
						return null
					}
				}

			},
		},
	};


	return (
		<>
			<div style={{ minHeight: 300 + 'px' }} className="d-flex align-items-center">
				<Doughnut options={options} data={data} />
			</div>
		</>
	);
}

export default OverviewDonutChart;