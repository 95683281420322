import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import axios from "axios";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ChartDonutSingleVal from "./ChartDonutSingleVal";
import ChartFilledSingleCircleGHG from "./ChartFilledSingleCircleGHG";
import { circularityAssessmentDets, scaleColoursCss, scaleColours, scaleValues, scaleValuesGHG } from './GlobalVars';
import './../index.css';
import { formatPercentage, formatGhge } from '../HelperFunctions'
import { loadStore } from "./AdminStoresPage";
import { config } from "../config";

const ProjectSummaryCharts = ({ store }) => {
	const backendUrl = useSelector((state) => state.backendUrl);
	const storeId = store.id;
	const [projectScores, setProjectScores] = useState({});
	const [projectGhg, setProjectGhg] = useState("");
	const [projectGhgInverted, setProjectGhgInverted] = useState("");
	const [projectGhgTxt, setProjectGhgTxt] = useState("");
	const [calcParams, setCalcParamList] = useState("");
	const [lowStore, setLowStore] = useState("");
	const [medStore, setMedStore] = useState("");
	const [highStore, setHighStore] = useState("");
	const [lowCounter, setLowCounter] = useState("");
	const [medCounter, setMedCounter] = useState("");
	const [highCounter, setHighCounter] = useState("");

	useEffect(() => {
		loadStore(storeId, store => {
			// console.log(store.GHGEmissions)
			var ghgValue = store.GHGEmissions;
			if (store.GHGEmissions) {
				setProjectGhg(ghgValue)
				setProjectGhgInverted(ghgValue)
			}
			else {
				setProjectGhg("NA")
				setProjectGhgTxt("")
				setProjectGhgInverted(1)
			}
		})

		axios.get(`${backendUrl}/materialInstancesByStore/${storeId}`)
			.then(function (response) {
				//console.log("materialInstancesByStore");
				//console.log(response.data);
				var storeMaterials = response.data;

				{
					circularityAssessmentDets.map((cat) => {
						var index = circularityAssessmentDets.indexOf(cat);
						var catName = cat.name;
						var countCat = storeMaterials.filter((store) => store.circularityAssessment === catName).map(data => data);
						//console.log(catName + " = " + countCat.length);
						if (storeMaterials.length != 0) {
							var percentage = (countCat.length / storeMaterials.length) * 100
							circularityAssessmentDets[index].value = Math.round(percentage * 100) / 100;
						}
						else {
							circularityAssessmentDets[index].value = "0"
						}
						//console.log(circularityAssessmentDets);
					})
				}
				setProjectScores(circularityAssessmentDets);
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);


	return (
		<Container>
			<Row>
				{Object.entries(projectScores).map(([key, cat], i) => (
					<Col xs={6} md={2} key={cat.key} className="m-0 p-1">
						<Row>
							<Col className="d-flex justify-content-center align-items-center">
								<h4 className="fadeText2s" style={{ position: "absolute" }}>{formatPercentage(cat.value)}%</h4>
								<ChartDonutSingleVal
									percentage={cat.value}
									label={cat.name}
									delay={1000 + (cat.id * 100)}
								/>
							</Col>
						</Row>
						<Row className="pt-2">
							<Col>
								<h6 className="text-center">{cat.name}</h6>
							</Col>
						</Row>
					</Col>
				))}

				<Col xs={6} md={2} className="m-0 p-1">
					<ChartFilledSingleCircleGHG
						//storeType={}
						locationType={store.locationType}
						ghgValue={store.GHGEmissions}
						label="Embodied"
						delay={1000 + (7 * 100)}
						bottomText=""
					/>
					{/*<Row>
						<Col className="d-flex justify-content-center align-items-center">
					 		<div className="fadeText2s" style={{ position: "absolute" }}>
								<h4 className="text-center">{formatGhge(projectGhg)}</h4>
								<h6 className="text-center">{projectGhgTxt}</h6>
							</div> 
							<ChartFilledSingleCircleGHG
								//storeType={}
								locationType={store.locationType}
								ghgValue={projectGhgInverted}
								label="Embodied"
								delay={1000 + (7 * 100)}
							/>
						</Col>
					</Row>
					<Row className="pt-2">
						<Col>
							<h6 className="text-center">GHG Emissions</h6>
							<p className="text-center text-muted"><small>(kgCO2e per sqm)</small></p>
						</Col>
					</Row>*/}
				</Col>
			</Row>
		</Container>
	);
}

export default ProjectSummaryCharts;