import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import { Chart as ChartJS, LinearScale, Tooltip, Legend, Title } from 'chart.js';
// import { Bar } from 'react-chartjs-2';
import { TreemapController, TreemapElement } from "chartjs-chart-treemap";
import { scaleColoursFive, scaleColours, scaleValues } from './GlobalVars'
import HelpModal from './HelpModal';

ChartJS.register(LinearScale, Tooltip, Legend, Title, TreemapController, TreemapElement);

const sampleData = [
	{ label: "Sample-Retained", value: 0, colour: scaleColoursFive[0] },
	{ label: "Sample-Reused", value: 0, colour: scaleColoursFive[1] },
	{ label: "Sample-Recycled", value: 0, colour: scaleColoursFive[2] },
	{ label: "Sample-Part recycled-Part virgin", value: 0, colour: scaleColoursFive[3] },
	{ label: "Sample-Virgin Grade", value: 0, colour: scaleColoursFive[4] }
]

const getColour = (ctx, treeData) => {
	if (ctx.type !== 'data') {
		return 'transparent';
	}
	const value = ctx.raw.v;
	const dataLabel = ctx.raw.g;

	var findLabel = treeData.filter((data) => data.label.includes(dataLabel));
	var labelColour = findLabel[0].colour;

	return labelColour
}

const getLabel = (ctx, treeData) => {
	const treeValue = ctx.raw.v;
	var treeIndex = ctx.dataset.data[ctx.dataIndex]._data._idx;
	var treeLabel = treeData[treeIndex].label;

	return treeLabel + " : " + treeValue
}

const ExampleChart = ({ treeData, options }) => {
	const canvas = useRef(null);
	useEffect(() => {
		//console.log('useEffect example chart')
		const ctx = canvas.current.getContext('2d');
		const chart = new ChartJS(ctx, {
			type: 'treemap',
			data: {
				datasets: [{
					tree: treeData,
					key: 'value',
					groups: ['label'],
					labels: {
						display: false,
						formatter: (ctx) => ctx.raw.g
					},
					backgroundColor: (ctx) => getColour(ctx, treeData),
					spacing: 1.2
				}]
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					title: {
						display: false,
					},
					legend: {
						display: false,
					},
					datalabels: false,
					tooltip: {
						callbacks: {
							title: function (item, data) {
								return "Material";
							},
							// label(ctx) {
							// 	//console.log(ctx.raw)
							// 	return ctx.raw.g
							// },
							label: (ctx) => getLabel(ctx, treeData),
						}
					}
				}
			}
		})
		return () => {
			//console.log('destroying example chart');
			chart.destroy();
		}
	}, [treeData, options]);

	//console.log('render example chart')

	return <div style={{ minHeight: 300 + 'px' }}><canvas ref={canvas}></canvas></div>
}

function OverviewTreemapChart(props) {
	const backendUrl = useSelector((state) => state.backendUrl);
	const canvas = useRef(null);
	const [treeData, setTreeData] = useState(sampleData);
	const [options, setOptions] = useState({});
	const [test, setTest] = useState(100)

	useEffect(() => {
		//console.log('useEffect chart')

		//get global scores
		axios.get(`${backendUrl}/materialCounts`)
			.then(function (response) {
				//console.log(response.data)

				var tempMaterials = response.data.rawMaterialCounters;

				var treeObject = [
					{ label: "Retained", value: tempMaterials.retm, colour: scaleColoursFive[0] },
					{ label: "Reused", value: tempMaterials.reum, colour: scaleColoursFive[1] },
					{ label: "Recycled", value: tempMaterials.recm, colour: scaleColoursFive[2] },
					{ label: "Part recycled-Part virgin", value: tempMaterials.prpvg, colour: scaleColoursFive[3] },
					{ label: "Virgin Grade", value: tempMaterials.vgm, colour: scaleColoursFive[4] },
				];

				//setTimeout(() => {
				//console.log('setting tree chart data!')
				setTreeData(treeObject)
				//}, 2000);

			})
			.catch(function (error) {
				console.log(error);
			});

		const updatedOptions = {
			type: "treemap",
			data: {
				datasets: [
					{
						tree: treeData,
						key: 'value',
						groups: ['label'],
						labels: {
							display: false,
							formatter: (ctx) => ctx.raw.g
						},
						backgroundColor: (ctx) => getColour(ctx, treeData),
						spacing: 1.2
					},
				]
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					title: {
						display: false,
					},
					legend: {
						display: false,
					},
					datalabels: false,
					tooltip: {
						callbacks: {
							title: function (item, data) {
								return "Material";
							},
							// label(ctx) {
							// 	//console.log(ctx.raw)
							// 	return ctx.raw.g
							// },
							label: (ctx) => getLabel(ctx),
						}
					}
				}
			}
		}

		setOptions(updatedOptions)
	}, []);

	//console.log('chart rendered')
	return (
		<div>
			<ExampleChart treeData={treeData} options={options} />
		</div>
	);
}

export default OverviewTreemapChart;